import React from 'react';
import {Header} from './components/header';
import {Main} from './main/main';
import {About} from './about/about';
import {Skills} from './skills/skills';
import {Works} from './works/works';
import {Contact} from './contact/contact';
import {Footer} from './components/footer.js';

import isInViewport from './components/viewport';
import Elements from './components/elements';

import './components/body.scss';
import './components/cssvar.css'

export class App extends React.Component{
    constructor(props){
        super(props);

        let dark = window.localStorage.getItem("dark_mode");
        if (!dark){
            dark = "false";
        }

        this.state = {
            dark : dark
        }
    }

    change_mode(){
        if (this.state.dark === "true"){
            this.setState({dark : "false"});
            window.localStorage.setItem("dark_mode", "false")
        } else {
            this.setState({dark : "true"});
            window.localStorage.setItem("dark_mode", "true")
        }
    }

    render(){
        return (<div className={'app'+ (this.state.dark === "true" ? " dark" : "")}>
            <Header dark_mode={this.change_mode.bind(this)}/>
            <Main/>
            <About/>
            <Skills/>
            <Works/>
            <Contact/>
            <Footer/>
        </div>)
    }
}
