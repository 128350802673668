import React, {useDebugValue, useEffect, useState} from 'react';
import VisibleComp from '../components/visibleComponent.js';
import './skills.scss';
import '../components/basicbox.scss';

class Skill extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            svg : ""
        }
    }

    componentDidMount(){ //Recupere les données du SVG de l'icone
        fetch(this.props.data.image_path).then(res => res.text()).then(body => {
            this.setState({
                svg : <div className='icon' dangerouslySetInnerHTML={{__html : body}}/>
            })
        });
    }

    render(){
        return (<span className='skill'>
            <div className='data'>     
                <p>{this.props.data.name}</p>
                {this.state.svg}
            </div>
            <div className="level" style={{"--l" : this.props.data.level*10 + "%"}}/>
        </span>)
    }
}

class SkillPan extends React.Component{
    render(){

        let elt = [];
        let long = 0;
        if (this.props.data){
            long = this.props.data.length
            this.props.data.forEach((element, index) => {
                elt.push(<Skill key={index} data={element}/>);
            });
        }
        return (<div className='skill-pan' style={{"--n" : long}}>
            <h3>{this.props.title}</h3>
            {elt}
        </div>)
    }
}


export const Skills = () => {
    const [data, setData] = useState(null)

    //const [enabled, setEnabled] = useState(false)
    /*
    <button className={"button"}>see more</button>
                {enabled &&
                    <div className={"window"}>

                    </div>
                }
     */


    useEffect(() => {
        fetch("/api/skills/all").then(res => res.json()).then((json) => {
            setData(json)
        });
    }, [])

    return (<VisibleComp name="skills" className='skills basic-box'>
        {data && (
            <>
            <h2>My <em>S</em>kills</h2>
            <div>
                <SkillPan data={data.front} title='Front-End'></SkillPan>
                <SkillPan data={data.back} title='Back-End'></SkillPan>
                <SkillPan data={data.others} title='Others'></SkillPan>
            </div>

            </>
        )}
    </VisibleComp>)
}


