import React, {useEffect, useState} from 'react';
import Elements from './elements';
import './header.scss';

import sun from '../images/sun.png';
import moon from '../images/moon.png';
import isInViewport from "./viewport";

export const Header = (props) => {
    const list = ["About", "Skills","Work", "Contact"];

    const [sectionFocus, setSectionFocus] = useState("fe")

    const handleClick = (section) => {
        let elements = Elements.fetch();
        if (elements[section]){
            let node = elements[section].current;
            if (node){
                node.scrollIntoView({block: "start", inline: "center"});
            }
        } else {
            console.warn("This section does not exist");
        }
    }

    const handleScroll = () => {
        let elts = Elements.fetch()
        let tmpSectionFocus = null
        let max = 0
        Object.keys(elts).forEach((key, index) => {
            if (list.map(value => value.toLowerCase()).includes(key)){
                let val = isInViewport(elts[key].current)
                if (max < val){
                    max = val
                    tmpSectionFocus = key
                }
            }
        })
        setSectionFocus((val) => {
            return val !== tmpSectionFocus ? tmpSectionFocus : val
        })
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])

    return (
        <header id='header' className='header'>
            <ul>
                {list.map((elt, index) => {
                    let attr = {key : index, onClick : () => {handleClick(elt.toLowerCase())}}
                    if (elt.toLowerCase() === sectionFocus){
                        attr.className = "selected"
                    }
                    return <li {...attr}>{elt}</li>
                }) }
            </ul>
            <div className='dark-mode-button' onClick={props.dark_mode}>
                <img id='sun' alt='sun' src={sun}/>
                <img id='moon' alt='moon' src={moon}/>
            </div>
        </header>
    )
}
